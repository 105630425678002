var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-12" },
    [
      _c("v-card-title", [
        _vm._v(
          " Details " + _vm._s(_vm.code) + " - " + _vm._s(_vm.description) + " "
        ),
      ]),
      _c("v-divider"),
      _c(
        "v-form",
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2" } },
                    [
                      _c("pga-text-field", {
                        attrs: { label: "Code", readonly: "" },
                        model: {
                          value: _vm.code,
                          callback: function ($$v) {
                            _vm.code = $$v
                          },
                          expression: "code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("pga-text-field", {
                        attrs: { label: "Description", readonly: "" },
                        model: {
                          value: _vm.description,
                          callback: function ($$v) {
                            _vm.description = $$v
                          },
                          expression: "description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("pga-text-field", {
                        attrs: { label: "Vendor", readonly: "" },
                        model: {
                          value: _vm.vendor,
                          callback: function ($$v) {
                            _vm.vendor = $$v
                          },
                          expression: "vendor",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("pga-switch", {
                        attrs: {
                          title: "Active",
                          "pga-aria-label": ["Active", "No Active"],
                          readonly: "",
                        },
                        model: {
                          value: _vm.isActive,
                          callback: function ($$v) {
                            _vm.isActive = $$v
                          },
                          expression: "isActive",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("pga-type-certification", {
                        attrs: { title: "Type", "explicit-tooltip": "" },
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3" } },
                    [
                      _c("pga-text-field", {
                        attrs: { label: "Last Modify", readonly: "" },
                        model: {
                          value: _vm.modifiedDate,
                          callback: function ($$v) {
                            _vm.modifiedDate = $$v
                          },
                          expression: "modifiedDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3" } },
                    [
                      _c("pga-text-field", {
                        attrs: { label: "Modified By", readonly: "" },
                        model: {
                          value: _vm.modifiedBy,
                          callback: function ($$v) {
                            _vm.modifiedBy = $$v
                          },
                          expression: "modifiedBy",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      _c("pga-data-table", {
                        attrs: {
                          title: "Users",
                          headers: _vm.headers,
                          data: _vm.users,
                          "col-props": ["AttainmentDate", "ExpireDate"],
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "column-AttainmentDate",
                            fn: function (props) {
                              return [
                                props.row.item.AttainmentDate != null
                                  ? _c("pga-certification-date-picker", {
                                      attrs: {
                                        label: "Achievement Date",
                                        readonly: true,
                                      },
                                      model: {
                                        value: props.row.item.AttainmentDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            props.row.item,
                                            "AttainmentDate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "props.row.item.AttainmentDate",
                                      },
                                    })
                                  : _c("span", {
                                      domProps: { innerHTML: _vm._s("--") },
                                    }),
                              ]
                            },
                          },
                          {
                            key: "column-ExpireDate",
                            fn: function (props) {
                              return [
                                props.row.item.ExpireDate != null
                                  ? _c("pga-certification-date-picker", {
                                      attrs: {
                                        label: "Expire Date",
                                        readonly: true,
                                      },
                                      model: {
                                        value: props.row.item.ExpireDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            props.row.item,
                                            "ExpireDate",
                                            $$v
                                          )
                                        },
                                        expression: "props.row.item.ExpireDate",
                                      },
                                    })
                                  : props.row.item.AttainmentDate != null
                                  ? _c("v-icon", {
                                      attrs: { large: "" },
                                      domProps: {
                                        textContent: _vm._s("mdi-infinity"),
                                      },
                                    })
                                  : _c("span", {
                                      domProps: { innerHTML: _vm._s("--") },
                                    }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }