var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.accessibility
        ? _c(
            "pga-bottom-navigation",
            [
              _c(
                "pga-authorized-content",
                { attrs: { permission: "EditCertification" } },
                [_c("pga-save-button", { on: { click: _vm.Create } })],
                1
              ),
              _c("pga-cancel-button", { on: { click: _vm.undo } }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "pga-skeleton-loader",
        { attrs: { "is-loaded": _vm.CertificationCreate.Command } },
        [
          !_vm.accessibility
            ? _c("pga-certification-create", {
                model: {
                  value: _vm.CertificationCreate.Command,
                  callback: function ($$v) {
                    _vm.$set(_vm.CertificationCreate, "Command", $$v)
                  },
                  expression: "CertificationCreate.Command",
                },
              })
            : _c("pga-certification-create-accessibility", {
                on: { "save-accessibility": _vm.Create },
                model: {
                  value: _vm.CertificationCreate.Command,
                  callback: function ($$v) {
                    _vm.$set(_vm.CertificationCreate, "Command", $$v)
                  },
                  expression: "CertificationCreate.Command",
                },
              }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }