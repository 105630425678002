var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { attrs: { elevation: "6" } }, [
    _c(
      "div",
      [
        !_vm.accessibility
          ? _c(
              "pga-bottom-navigation",
              [
                _c(
                  "pga-authorized-content",
                  { attrs: { permission: "EditCertification" } },
                  [_c("pga-save-button", { on: { click: _vm.SelectUsers } })],
                  1
                ),
                _c("pga-cancel-button", { on: { click: _vm.undo } }),
              ],
              1
            )
          : _vm._e(),
        !_vm.accessibility
          ? _c("pga-certifications-select-users", {
              model: {
                value: _vm.CertificationsSelectUsers.Command,
                callback: function ($$v) {
                  _vm.$set(_vm.CertificationsSelectUsers, "Command", $$v)
                },
                expression: "CertificationsSelectUsers.Command",
              },
            })
          : _c("pga-certifications-select-users-accessibility", {
              on: { "save-accessibility": _vm.SelectUsers },
              model: {
                value: _vm.CertificationsSelectUsers.Command,
                callback: function ($$v) {
                  _vm.$set(_vm.CertificationsSelectUsers, "Command", $$v)
                },
                expression: "CertificationsSelectUsers.Command",
              },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }