var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.accessibility
        ? _c(
            "pga-bottom-navigation",
            [
              _c(
                "pga-authorized-content",
                { attrs: { permission: "EditCertification" } },
                [_c("pga-edit-button", { on: { click: _vm.Edit } })],
                1
              ),
              _c(
                "pga-authorized-content",
                { attrs: { permission: "SelectUsersCertification" } },
                [
                  _c("pga-edit-button", {
                    attrs: {
                      text: "Select Users",
                      icon: "mdi-account-multiple-plus",
                    },
                    on: { click: _vm.SelectUsers },
                  }),
                ],
                1
              ),
              _c(
                "pga-authorized-content",
                { attrs: { permission: "DeleteCertification" } },
                [
                  _c("pga-delete-button", {
                    on: { click: _vm.ShowDialogDelete },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "pga-skeleton-loader",
        { attrs: { "is-loaded": _vm.CertificationDetails } },
        [
          !_vm.accessibility
            ? _c("pga-certification-details", {
                model: {
                  value: _vm.CertificationDetails,
                  callback: function ($$v) {
                    _vm.CertificationDetails = $$v
                  },
                  expression: "CertificationDetails",
                },
              })
            : _c("pga-certification-details-accessibility", {
                on: {
                  "edit-accessibility": _vm.Edit,
                  "select-users-accessibility": _vm.SelectUsers,
                },
                model: {
                  value: _vm.CertificationDetails,
                  callback: function ($$v) {
                    _vm.CertificationDetails = $$v
                  },
                  expression: "CertificationDetails",
                },
              }),
          _c("pga-dialog", {
            attrs: {
              title: _vm.title,
              "icon-title": "mdi-delete-alert",
              message: _vm.message,
              color: "orange",
              "icon-button": "mdi-checkbox-marked-circle-outline",
            },
            on: { agree: _vm.Delete, reject: _vm.HideDialog },
            model: {
              value: _vm.showDialog,
              callback: function ($$v) {
                _vm.showDialog = $$v
              },
              expression: "showDialog",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }