var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("pga-data-table", {
    attrs: {
      title: "Users with the certification " + _vm.description,
      headers: _vm.headers,
      data: _vm.listUsers,
      loading: _vm.isLoading,
      "item-key": "Id",
      "show-select": "",
      "col-props": ["AttainmentDate", "ExpireDate"],
    },
    scopedSlots: _vm._u([
      {
        key: "column-AttainmentDate",
        fn: function (props) {
          return [
            _vm.showDate(props.row.item.Id)
              ? _c("pga-certification-date-picker", {
                  attrs: {
                    label: "Achievement Date",
                    value: _vm.getAttainmentDate(props.row.item.Id),
                    id: props.row.item.Id,
                    "key-error": "AttainmentDate",
                    "row-key-error": "AttainmentDateUserIds",
                    errors: _vm.Errors,
                  },
                  on: { "change-date": _vm.setAttainmentDate },
                })
              : _vm._e(),
          ]
        },
      },
      {
        key: "column-ExpireDate",
        fn: function (props) {
          return [
            _vm.showDate(props.row.item.Id)
              ? _c("pga-certification-date-picker", {
                  attrs: {
                    label: "Expire Date",
                    "label-checkbox": "Set Expire",
                    value: _vm.getExpireDate(props.row.item.Id),
                    id: props.row.item.Id,
                    "key-error": "ExpireDate",
                    "row-key-error": "ExpireDateUserIds",
                    errors: _vm.Errors,
                    nullable: true,
                  },
                  on: { "change-date": _vm.setExpireDate },
                })
              : _vm._e(),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selectedUsers,
      callback: function ($$v) {
        _vm.selectedUsers = $$v
      },
      expression: "selectedUsers",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }