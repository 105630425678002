var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [
        _vm._v("Details " + _vm._s(_vm.code) + " - " + _vm._s(_vm.description)),
      ]),
      _c("v-divider"),
      _c(
        "v-form",
        { attrs: { role: "form", "aria-label": "Certificatio Details Page" } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12", sm: "2" } }, [
                    _c("label", { attrs: { for: "code" } }, [
                      _c("h3", [_vm._v("Code:")]),
                    ]),
                    _c("br"),
                    _c("span", { attrs: { id: "code" } }, [
                      _vm._v(" " + _vm._s(_vm.code) + " "),
                    ]),
                  ]),
                  _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                    _c("label", { attrs: { for: "description" } }, [
                      _c("h3", [_vm._v("Description:")]),
                    ]),
                    _c("br"),
                    _c("span", { attrs: { id: "description" } }, [
                      _vm._v(" " + _vm._s(_vm.description) + " "),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
                    _c("label", { attrs: { for: "vendor" } }, [
                      _c("h3", [_vm._v("Vendor:")]),
                    ]),
                    _c("br"),
                    _c("span", { attrs: { id: "vendor" } }, [
                      _vm._v(" " + _vm._s(_vm.vendor) + " "),
                    ]),
                  ]),
                  _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
                    _c("label", { attrs: { for: "active" } }, [
                      _c("h3", [_vm._v("Is Active:")]),
                    ]),
                    _c("br"),
                    _c("span", { attrs: { id: "active" } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.isActive ? "Active" : "No Active") +
                          " "
                      ),
                    ]),
                  ]),
                  _c("v-col", { attrs: { cols: "12", sm: "4" } }, [
                    _c("label", { attrs: { for: "type" } }, [
                      _c("h3", [_vm._v("Type")]),
                    ]),
                    _c("br"),
                    _c("span", { attrs: { id: "type" } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.type === 1 ? "Exam" : "Certification") +
                          " "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12", sm: "12" } }, [
                    _c("table", { attrs: { role: "table" } }, [
                      _c("thead", { attrs: { role: "rowgroup" } }, [
                        _c("caption", [_c("h3", [_vm._v("Users")])]),
                        _c("tr", [
                          _c("th", { attrs: { role: "columnheader" } }, [
                            _vm._v(_vm._s(_vm.headers[0].text)),
                          ]),
                          _c("th", { attrs: { role: "columnheader" } }, [
                            _vm._v(_vm._s(_vm.headers[1].text)),
                          ]),
                          _c("th", { attrs: { role: "columnheader" } }, [
                            _vm._v(_vm._s(_vm.headers[2].text)),
                          ]),
                        ]),
                      ]),
                      _vm.users.length !== 0
                        ? _c(
                            "tbody",
                            { attrs: { role: "rowgroup" } },
                            _vm._l(_vm.users, function (u) {
                              return _c(
                                "tr",
                                { key: u.Id, attrs: { role: "row" } },
                                [
                                  _c("td", { attrs: { role: "cell" } }, [
                                    _vm._v(_vm._s(u.FullName)),
                                  ]),
                                  _c("td", { attrs: { role: "cell" } }, [
                                    _vm._v(
                                      _vm._s(_vm.formatDate(u.AttainmentDate))
                                    ),
                                  ]),
                                  _c("td", { attrs: { role: "cell" } }, [
                                    _vm._v(
                                      _vm._s(_vm.formatDate(u.ExpireDate))
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            0
                          )
                        : _c("tfoot", { attrs: { role: "rowgroup" } }, [
                            _c("tr", { attrs: { role: "row" } }, [
                              _c(
                                "td",
                                {
                                  staticStyle: { "text-align": "center" },
                                  attrs: { colspan: "3", role: "cell" },
                                },
                                [_vm._v(" No elements for this table ")]
                              ),
                            ]),
                          ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12", sm: "3" } }, [
                    _c(
                      "button",
                      {
                        staticClass: "edit",
                        attrs: { type: "button" },
                        on: { click: _vm.editAccessibility },
                      },
                      [_vm._v(" Edit ")]
                    ),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", "offset-sm": "1" } },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "selectusers",
                          attrs: { type: "button" },
                          on: { click: _vm.selectUsersAccessibility },
                        },
                        [_vm._v(" Select Users ")]
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", "offset-sm": "1" } },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "certifications",
                          attrs: { type: "button" },
                          on: { click: _vm.tableCertifications },
                        },
                        [_vm._v(" List Certifications ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }