var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-12" },
    [
      _c("v-card-title", [
        _vm._v(" Create Certification/Exam " + _vm._s(_vm.description) + " "),
      ]),
      _c("v-divider"),
      _c(
        "v-form",
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2" } },
                    [
                      _c("pga-text-field", {
                        attrs: {
                          label: "Code",
                          "key-error": "Code",
                          errors: _vm.Errors,
                        },
                        model: {
                          value: _vm.code,
                          callback: function ($$v) {
                            _vm.code = $$v
                          },
                          expression: "code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("pga-text-field", {
                        attrs: {
                          label: "Description",
                          "key-error": "Description",
                          errors: _vm.Errors,
                        },
                        model: {
                          value: _vm.description,
                          callback: function ($$v) {
                            _vm.description = $$v
                          },
                          expression: "description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("pga-combobox", {
                        attrs: {
                          "item-value": "Id",
                          "item-text": "BusinessName",
                          items: _vm.vendorsDropDown,
                          label: "Vendor",
                          multiple: false,
                          "return-object": "",
                          "key-error": "VendorId",
                          errors: _vm.Errors,
                        },
                        model: {
                          value: _vm.vendorId,
                          callback: function ($$v) {
                            _vm.vendorId = $$v
                          },
                          expression: "vendorId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3" } },
                    [
                      _c("pga-switch", {
                        attrs: {
                          title: "Active",
                          "pga-aria-label": ["Active", "No Active"],
                        },
                        model: {
                          value: _vm.isActive,
                          callback: function ($$v) {
                            _vm.isActive = $$v
                          },
                          expression: "isActive",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("pga-select", {
                        attrs: {
                          label: "Type",
                          "item-value": "Value",
                          "item-text": "Name",
                          items: _vm.typeCertification,
                        },
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }